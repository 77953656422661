<template>
  <RFModal :modalId="modalId" title="Payment" :loading="loading"
  @send="confirm" ok-label="Confirm" cancel-label="Cancel" has-cancel>
    <ValidationObserver ref="validator">
      <form class="my-5" @submit.prevent>
        <RFValidation name="Execution date" rules="required">
          <RFDatePicker label="Execution date" v-model="executionDate" :max="today"/>
        </RFValidation>

        <RFValidation name="CRO" rules="required">
          <RFInput label="CRO" v-model="cro" />
        </RFValidation>
      </form>
    </ValidationObserver>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFInput from '@/components/forms/RFInput'
import RFValidation from '@/components/forms/RFValidation'
import { adminService } from '@/services/admin'

import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'PaymentModal',
  components: {
    RFModal,
    RFDatePicker,
    RFInput,
    RFValidation,
  },
  data() {
    return {
      modalId: 'payment-modal',
      executionDate: null,
      cro: null,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      payment: state => state.adminPaymentStore.payment,
    }),
    today() {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    async confirm() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true

        const form = {
          dataEsecuzione: this.executionDate,
          codiceTransazione: this.cro,
        }

        const { data: payment, status } = await adminService.confirmPayment(this.payment.id, form)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Payment correctly confirmed!`))
          this.$emit('confirm', payment)
          this.close()
        } else {
          this.$toasted.error(this.$i18n.t(`An error occurred. Try again.`))
        }

        this.loading = false
      }
    },
    close() {
      this.$root.$emit('bv::hide::modal', this.modalId)
    }
  }
}
</script>

<style>

</style>
