<template>
  <div className="all-payments">
    <BankTransferTable title="Bank Transfer" :list="bankTransferList" :fields="fields" :loading="loading"
                       @update-list="update" @open-modal="openModal"/>
    <PaymentModal @confirm="updatePayment"/>
  </div>
</template>

<script>
import BankTransferTable from '@/components/admin/BankTransferTable'
import PaymentModal from '@/components/admin/payments/PaymentModal'

import {adminService} from '@/services/admin'
import {paymentStatus} from '@/enums'

import {Payment} from '@/models/payment.js'

export default {
  name: 'AdminBankTransferList',
  components: {
    BankTransferTable,
    PaymentModal,
  },
  data() {
    return {
      paymentStatus,

      bankTransferList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        {label: 'ID', key: 'id'},
        {label: 'Contract code', key: 'codiceContratto'},
        {label: 'Description', key: 'description'},
        {label: 'Firstname', key: 'firstname'},
        {label: 'Lastname', key: 'lastname'},
        {label: 'Email', key: 'email'},
        {label: 'Amount', key: 'amount', type: 'cash'},
        {label: 'Status', key: 'status'},
        {key: 'actions', label: 'Actions', type: 'actions', actions: ['show']},
      ]
    }
  },
  beforeMount() {
    this.update()
  },
  methods: {
    openModal(payment) {
      this.$store.commit('adminPaymentStore/setPayment', payment)
      this.$root.$emit('bv::show::modal', 'payment-modal')
    },
    async update() {
      this.loading = true

      this.bankTransferList = []

      const auxList = await adminService.getBankTransferList()

      await Promise.all(
        auxList.map(async (element, index) => {
          this.bankTransferList.push(element)
        })
      )

      this.loading = false
    },
    updatePayment(updatedPayment) {
      const index = this.bankTransferList.findIndex(payment => payment.id === updatedPayment.id)
      this.bankTransferList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.all-payments {
  .status {
    border-radius: 20px;
    text-align: center;
    color: $white;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 0 5px;

    &.ok {
      background-color: $success;
    }

    &.ko {
      background-color: $danger;
    }

    &.scheduled {
      background-color: $green;
    }

    &.pending {
      background-color: $dark-blue;
    }
  }
}
</style>
